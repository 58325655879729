import React from 'react';
import '../styles/News.css';

function News() {
  const articles = [
    { title: "2023年度関東大学バドミントン秋季リーグ戦の結果を載せました", content: "2023年度関東大学バドミントン秋季リーグ戦の結果を載せました", date: "2023-09-30" },
    { title: "10月の予定を載せました", content: "10月の予定を載せました", date: "2023-09-30" },
    { title: "9月の予定を載せました", content: "9月の予定を載せました", date: "2023-08-29" },
    { title: "ホームページをリニューアルしました", content: "当サイトのホームページが新しくなりました！", date: "2023-08-18" },
    // その他の記事データ
  ];

  return (
    <div className="news-container">
      {articles.map((article, index) => (
        <div key={index} className="news-article">
          <h3 className="news-title">{article.title}</h3>
          <p className="news-content">{article.content}</p>
          <span className="news-date">{article.date}</span> {/* ここで日付を表示 */}
        </div>
      ))}
    </div>
  );
}

export default News;
