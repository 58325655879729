import React from 'react';
import '../styles/Games.css';
import image_20230924 from '../images/20230924.jpg'; 

function Games() {
  return (
    <div className="games-container">
      <div className="games-article">
        <h3>関東大学バドミントン秋季リーグ戦</h3>
        <p>男子5部D3位でした<span role="img" aria-label="description of emoji">🎉</span></p>
        <p><a href="http://www.kantoibf.com/data/events/000449/004842.pdf" target="_blank" rel="noopener noreferrer">詳細な結果</a></p>
        <img src={image_20230924} alt="Trophy" className="trophy-image" />
      </div>
    </div>
  );
}


export default Games;