import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import '../styles/Schedule.css';

function Schedule() {
  const [scheduleData, setScheduleData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);

  useEffect(() => {
    try {
      const csvFilePath = require(`../schedules/${currentYear}/${currentMonth}.csv`);

      Papa.parse(csvFilePath, {
        download: true,
        header: true,
        complete: (result) => {
          setScheduleData(result.data);
          setErrorMessage(""); // データが正しくロードされた場合、エラーメッセージをリセット
        }
      });
    } catch (error) {
      console.error("CSV not found:", error);
      setErrorMessage("この月のスケジュールはまだ更新されていません。");
      setScheduleData([]); // スケジュールデータをクリア
    }
  }, [currentYear, currentMonth]);

  return (
    <div className="calendar-container">
      <div className="month-controls">
      <button onClick={() => {
        if (currentMonth === 1) {
          setCurrentYear(prev => prev - 1);
          setCurrentMonth(12);
        } else {
          setCurrentMonth(prev => prev - 1);
        }
      }}>前の月</button>
      <button onClick={() => {
        if (currentMonth === 12) {
          setCurrentYear(prev => prev + 1);
          setCurrentMonth(1);
        } else {
          setCurrentMonth(prev => prev + 1);
        }
      }}>次の月</button>
      </div>
      <h2>スケジュール {currentYear}年 {currentMonth}月</h2>
      
      {errorMessage ? (
        <p className="error-message">{errorMessage}</p>
      ) : (
        <div className="schedule-table">
          <table>
            <thead>
              <tr>
                <th>日付</th>
                <th>内容</th>
                <th>時間</th>
                <th>場所</th>
              </tr>
            </thead>
            <tbody>
              {scheduleData.map((schedule, index) => (
                <tr key={index}>
                  <td>{schedule.日付}</td>
                  <td>{schedule.内容}</td>
                  <td>{schedule.時間}</td>
                  <td>{schedule.場所}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Schedule;
