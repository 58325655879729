import React, { useState, useEffect } from 'react';
import '../styles/Home.css';

const pathHOMEImages = "../images/HomeImages";

function Home() {
    const requireContext = require.context("../images/HomeImages",false,/\.(jpg|jpeg|png)$/); // 画像の配列
    const images = requireContext.keys().reduce((acc, file) => {
        acc.push(requireContext(file));
        return acc;
    }, []);
    
    const [currentIndex, setCurrentIndex] = useState(0); // 現在の画像のインデックス

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000);

        return () => clearInterval(interval); // コンポーネントがアンマウントされるときにタイマーをクリア
    }, [images]);

    return (
        <section className="home-section">
            <h2>HOME</h2>
            <img src={images[currentIndex]} alt="Content" className="home-image" />
            <p>我々東京工業大学バドミントン部は、男子4部昇格を目指して日々練習に取り組んでいます。</p>
            <p>当HP以外に、東工大バド部のHPが存在しますが、それらは過去のものになります。ご注意ください。</p>
        </section>
    );
}

export default Home;
