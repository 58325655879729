import React from 'react';
import '../styles/ObOg.css';

function ObOg() {
  return (
    <section className="ob-og-section">
    <div>
      <h1>OB・OG会</h1>
      <p>卒業生の皆様へ、ここではOB・OG会の活動や情報を共有しています。</p>
      <h3>次回のOB・OG会のイベント</h3>
      <p>次回の集まりは2023年8月19日に開催予定です。楽しい交流会になるよう計画していますので、ぜひご参加ください！</p>
      <h3>連絡先</h3>
      <p>OB・OG会に関する問い合わせや提案などがあれば、以下のメールアドレスにお気軽にお問い合わせください。</p>
      <address>
        Email: <a href="tokyotechbadwelcome@gmail.com">tokyotechbadwelcome@gmail.com</a>
      </address>
      {/* 他の関連情報もここに追加できます */}
    </div>
    </section>
  );
}

export default ObOg;
