import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import Home from './Home';
import News from './News';
import Schedule from './Schedule';
import Games from './Games';
import Members from './Members';
import Join from './Join';
import ObOg from './ObOg';
import '../styles/App.css';
import instagramIcon from '../images/sns/instagram.png';
import xIcon from '../images/sns/x.png';

function App() {

  const now = new Date();
  const year = now.getFullYear();

  return (
    <div className="app-container">
    <>
    <Helmet>
      <meta name="keywords" content="東京工業大学,バドミントン,バドミントン部,東工大,バド部" />
    </Helmet>
    <Router>
      <nav className="navbar">
        <Link to="/">ホーム</Link>
        <Link to="/news">ニュース</Link>
        <Link to="/schedule">スケジュール</Link>
        <Link to="/games">試合</Link>
        <Link to="/members">メンバー</Link>
        <Link to="/join">入部案内</Link>
        <Link to="/obog">OB・OG会</Link>
      </nav>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/news" element={<News />} />
      <Route path="/schedule" element={<Schedule />} />
      <Route path="/games" element={<Games />} />
      <Route path="/members" element={<Members />} />
      <Route path="/join" element={<Join />} />
      <Route path="/obog" element={<ObOg />} />
    </Routes>
    <footer className="footer">
        <p>© {year} Tokyo Tech Badminton Club. All rights reserved.</p>
        <a href="https://www.instagram.com/tokyotech_badminton/" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" width="24" height="24" />
        </a>
        <a href="https://twitter.com/tokyotech_bad" target="_blank" rel="noopener noreferrer">
            <img src={xIcon} alt="Twitter" width="24" height="24" />
        </a>
    </footer>
  </Router>
  </>
  </div>
  );
}

export default App;
