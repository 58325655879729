import React from 'react';
import '../styles/Members.css';
import defaultImage from '../images/members/default.png';



const pathImages = '../images/members';

const requireContext = require.context('../images/members',true,/\.(jpg|jpeg|png)$/);
const memberImages = requireContext.keys().reduce((acc, file) => {
  const imageName = file.replace('./', '').replace(/\.\w+$/, '').replace(/^.*?B\//,"");
  acc[imageName] = requireContext(file);
  return acc;
}, {});

const now = new Date();
const year = now.getFullYear();

function getGrade(entryYear) {
  const progress = year - entryYear;
  if (progress < 4) {
    return "B" + String(progress + 1)
  } else if (progress < 6) {
    return "M" + String(progress - 3)
  } else {
    return "OB/OG"
  }
}


const membersData = [
  {
    entryYear: 2019,
    members: [
      { 
        name: '若宮大志郎', 
        role: null, 
        img: defaultImage,
        school: '海陽',
        faculty: '生命理工学系'
      },
      ]
      },
  {
    entryYear: 2020,
    members: [
      { 
        name: '土屋翔太朗', 
        role: null, 
        img: memberImages["tsuchiya"],
        school: '浅野',
        faculty: '土木・環境工学系'
      },
      { 
        name: '武部鳳太', 
        role: null, 
        img: defaultImage,
        school: '芝浦工業大学柏',
        faculty: '機械系'
      },
      { 
        name: '上田浩輔', 
        role: null, 
        img: defaultImage,
        school: '県立千葉',
        faculty: '電気電子系'
      },
      { 
        name: '藤野直哉', 
        role: null, 
        img: defaultImage,
        school: '国立',
        faculty: '生命理工学系'
      },
      { 
        name: '田垣好偲', 
        role: null, 
        img: defaultImage,
        school: '釧路湖陵',
        faculty: '材料系'
      },
            ]
      },
  {
    entryYear: 2021,
    members: [
      { 
        name: '小澤秀真', 
        role: null, 
        img: memberImages["kozawa"],
        school: '東葛飾',
        faculty: 'システム制御系'
      },
      { 
        name: '木村真也', 
        role: null, 
        img: memberImages["kimura"],
        school: '桐蔭学園',
        faculty: '生命理工学系'
      },
      { 
        name: '中村泰士', 
        role: null, 
        img: memberImages["nakamura"],
        school: '中央大学附属横浜',
        faculty: '情報工学系'
      },
    ]
  },
  {
    entryYear: 2022,
    members: [
      /*
      { 
        name: '鈴木丈翔', 
        role: '工大祭', 
        img: suzukiImage,
        school: '須磨学園',
        faculty: '機械系'
      },
      { 
        name: '鹿田塁', 
        role: '合宿、新歓、ホームページ', 
        img: defaultImage,
        school: '長岡',
        faculty: '情報通信系'
      },
      { 
        name: '山口悠斗', 
        role: '', 
        img: yamaguchiImage,
        school: '相模原中等',
        faculty: '物質理工'
      },
      */
      { 
        name: '川合徹郎', 
        role: '主務', 
        img: memberImages["kawai"],
        school: '小山台',
        faculty: '生命理工学系'
      },
      { 
        name: '北岡匠太', 
        role: 'OB会', 
        img: defaultImage,
        school: '',
        faculty: '材料系'
      },
      { 
        name: '酒井勇斗', 
        role: '主将', 
        img: memberImages["sakai"],
        school: '本郷',
        faculty: '材料系'
      },
      { 
        name: '吉田駿太', 
        role: '会計', 
        img: memberImages["yoshida"],
        school: '逗子開成',
      faculty: '材料系'
      },
      {
        name: '川上聖大',
        role: '工大祭',
        img: defaultImage,
        school: '竹園',
        faculty: '物理学系'
      },
      ]
  },
  {
    entryYear: 2023,
    members: [
      { 
        name: '米田瑞生', 
        role: null, 
        img: memberImages["yoneda"],
        school: '本郷',
        faculty: '工'
      },
      { 
        name: '石原拓馬', 
        role: null, 
        img: memberImages["ishihara"],
        school: '小山台',
        faculty: '物質理工'
      },
      /*
      { 
        name: '本間悠太', 
        role: null, 
        img: honmaImage,
        school: '平塚江南',
        faculty: '工'
      },
      */
      { 
        name: '山村誓', 
        role: null, 
        img: memberImages["yamamura"],
        school: '伊勢',
        faculty: '生命'
      },
      { 
        name: '片山尚哉', 
        role: null, 
        img: defaultImage,
        school: '国立',
        faculty: '理'
      },
      /*
      { 
        name: '陳禹杉', 
        role: null, 
        img: defaultImage,
        school: '东北育才外国语学校',
        faculty: '工'
      },
      { 
        name: '張效菘', 
        role: null, 
        img: defaultImage,
        school: '毘明市第十中學',
        faculty: '工'
      },
      */
    ]
  }
  /*
  {
    entryYear: 2024,
    members: [
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},

    ]
  }
  */
  // 他の学年のデータもここに追加
];

function Members() {
  
  return (
    <section className="members-section">
      <h2>メンバー</h2>
      <p>現在の部員一覧</p>
      {membersData.map((gradeData, index) => (
          <div key={index} className="grade-section">
            <h3>{getGrade(gradeData.entryYear)}</h3>
            {gradeData.members.map((member, index) => (
              <div key={index} className="member">
                <img src={member.img || defaultImage} alt={member.name} className="member-image" />
                <p><strong>{member.name}</strong></p>
                <p>系 : {member.faculty}</p>
                <p>出身高校 : {member.school}</p>
                {member.role != null ? <p>役割 : {member.role}</p> : null}
              </div>
            ))}
          </div>
      ))}
    </section>
  );
}

export default Members;
