import React from 'react';
import '../styles/Join.css';

function Join() {
  return (
    <div className="join-container">
      <h1>入部案内</h1>
      <p>入部に関する質問や不明点があれば、お気軽にお問い合わせください。</p>
      <p>いつでも入部希望歓迎！</p>
      <p>SNSボタンをクリックしてDMを送ってください。</p>
      <p>インスタグラムとTwitter好きな方から連絡をお待ちしています。</p>

      <div className="activity-location">
        <h2>活動場所</h2>
        <p>東京工業大学体育館</p>
        <h3>郵便番号</h3>
        <p>〒152-8550</p>
        <h3>住所</h3>
        <p>東京都目黒区大岡山２丁目１２−１</p>
      </div>
    </div>
  );
}

export default Join;
